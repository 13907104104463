<template>
  <div class="tools-page">
    <v-container>
      <v-row align="start">
        <v-col cols="3">
          <h3>{{ $t('stackDesignTitle') }}</h3>
          <span>{{ $t('stackDesignDescription') }}</span>
        </v-col>
        <v-col cols="5">
          <div class="d-flex flex-column">
            <router-link
              class="tools-page__tool unstyled-link"
              :to="{ name: 'infraImport' }">
              <div>
                <h3 class="tool-title">
                  {{ $t('infraImportTitle') }}
                </h3>
                <div class="tools-page__container">
                  <span class="tools-page__description">
                    {{ $t('InfraImportDescription') }}
                    <a
                      :href="$docLinks.infraImport.index"
                      class="cy-link"
                      target="_blank"
                      @click.stop>
                      {{ $t('learnMore') }}
                    </a>
                  </span>
                </div>
              </div>
              <v-icon>chevron_right</v-icon>
            </router-link>
            <router-link
              class="tools-page__tool unstyled-link"
              :to="{ name: 'formsEditor' }">
              <div>
                <h3 class="tool-title">
                  {{ $t('stackFormsEditorTitle') }}
                </h3>
                <div class="tools-page__container">
                  <span class="tools-page__description">{{ $t('stackFormsEditorDescription') }}</span>
                </div>
              </div>
              <v-icon>chevron_right</v-icon>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageInfraTools',
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.infraTools'), [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.stacksSection'),
      description: {
        text: this.$t('routes.stacksSectionDescription'),
        link: this.$docLinks.stacks.overview,
      },
    }
  },
  i18n: {
    messages: {
      en: {
        title: '@:InfraTools',
        InfraImportDescription: 'Create stacks and project by importing an existing cloud infrastructure. InfraImport is the perfect tool to start using Cycloid and to ease the transition to infrastructure-as-code.',
        infraImportTitle: '@:InfraImport',
        stackDesignDescription: 'Tools to help you author and maintain @:Stacks.',
        stackDesignTitle: '@:Stack design',
        stackFormsEditorDescription: 'Write and edit your StackForms .forms.yml file with live preview and validation.',
        stackFormsEditorTitle: 'StackForms editor',
        tryIt: 'Try it',
      },
      es: {
        title: '@:InfraTools',
        InfraImportDescription: 'Cree stacks y proyectos importando una infraestructura de nube existente. InfraImport es la herramienta perfecta para comenzar a usar Cycloid y facilitar la transición a la infraestructura como código.',
        infraImportTitle: '@:InfraImport',
        stackDesignDescription: 'Herramientas para ayudarle a crear y mantener @:Stacks.',
        stackDesignTitle: 'Diseño de @:Stack',
        stackFormsEditorDescription: 'Escriba y edite su file StackForms .forms.yml con vista previa y validación en vivo.',
        stackFormsEditorTitle: 'StackForms editor',
        tryIt: 'Intentalo',
      },
      fr: {
        title: '@:InfraTools',
        InfraImportDescription: `Créez des stacks et des projets en important une infrastructure cloud existante. InfraImport est l'outil parfait pour commencer à utiliser Cycloid et faciliter la transition vers l'infrastructure as code`,
        infraImportTitle: '@:InfraImport',
        stackDesignDescription: 'Des outils pour vous aider à créer et à gérer des @:Stacks.',
        stackDesignTitle: 'Conception de @:Stack',
        stackFormsEditorDescription: 'Ecrivez et editez votre fichier .forms.yml StackForms avec un aperçu et une validation en temps réel.',
        stackFormsEditorTitle: 'StackForms editor ',
        tryIt: 'Essayez-le',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tools-page {
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  &__header {
    padding: 24px;
    border-bottom: 1px solid cy-get-color("grey");
  }

  &__tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 24px;
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;
    background-color: cy-get-color("white");
    cursor: pointer;

    .tool-title {
      padding-bottom: 8px;
    }

    .v-icon {
      color: cy-get-color("primary");
    }
  }

  &__description {
    max-width: 486px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 590px;
  }
}
</style>
